<template>
<div>
  <div class="vx-row" v-if="item">
    <div class="vx-col w-full lg:w-1/1">
      <!-- ABOUT CARD -->
      <vx-card :title="`Detail ${$i18n.t('JobTracking')}`" class="mt-base">
        <!-- ACTION SLOT -->
        <template slot="actions">
          <vs-button color="danger" icon-pack="feather" icon="icon-arrow-left" type="border" @click="$router.push({ name: 'job-tracking' })">
            Kembali
          </vs-button>
        </template>

        <div class="vx-row">
          <div class="vx-col w-full lg:w-1/2" v-for="(cell, i) in cells" :key="i">
            <div class="mt-5">
              <h6 class="mb-1">{{ cell.header }}</h6>
              <p v-if="cell.format">
                {{ cell.format(item[cell.field]) }}
              </p>
              <p v-else>
                {{ item[cell.field] }}
              </p>
            </div>
          </div>
        </div>


      </vx-card>

      <vx-card :title="`Last Activity`" v-if="last_activity.length > 0" class="mt-base">

        <div class="vx-row" v-for="(l, i) in last_activity" :key="i">
          <div class="vx-col w-full lg:w-1/2">
            <div class="mt-5">
              <h6 class="mb-1">Datetime</h6>
              <p>
                {{ l.date }} {{ l.time }}
              </p>
            </div>
          </div>

          <div class="vx-col w-full lg:w-1/2">
            <div class="mt-5">
              <h6 class="mb-1">PIC</h6>
              <p>
                {{ l.pic.employee.name }}
              </p>
            </div>
          </div>

          <div class="vx-col w-full lg:w-1/1">
            <div class="mt-5">
              <h6 class="mb-1">Activity</h6>
              <p style="word-break: break-all">
                {{ l.activity }}
              </p>
            </div>
          </div>

          <vs-divider class="mt-5"></vs-divider>

        </div>


      </vx-card>

    </div>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "JobDetail",
  data: () => ({
    last_activity: false
  }),
  computed: {
    ...mapGetters({
      item: 'job/showItem',
    }),
    cells () {
      return [
        {
          header: this.$i18n.t('JobTitle'),
          field: 'title'
        },
        {
          header: this.$i18n.t('CreatedBy'),
          field: 'pic1',
          format: (item) => {
            return item.employee.name;
          }
        },
        {
          header: this.$i18n.t('Description'),
          field: 'description'
        },
        {
          header: this.$i18n.t('CreatedAt'),
          field: 'date'
        },
        {
          header: this.$i18n.t('Status'),
          field: 'status'
        },
      ]
    }
  },
  created () {
    this.id = this.$secure.decrypt(this.$route.params.id);
    this.$store.dispatch('job/detailItem', {id: this.id}).then(res => {
      console.log(res.data.values);
        this.last_activity = res.data.values.last_activity;
    });
  }
}
</script>

<style scoped>

</style>
